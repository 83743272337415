.floating-menu {
    position: fixed; /* Fix the menu to the bottom of the screen */
    left: 10px;
    right: 10px;
    bottom: 10px; /* Stick to the bottom of the viewport */
    padding-bottom: env(safe-area-inset-bottom); /* Account for the safe area on mobile devices */
    z-index: 10; /* Ensure it stays on top of other content */
    display: flex;
    justify-content: space-around;
    background-color: white;
    padding: 8px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #ddd;
    border-radius: 10px;
    max-width: 430px;
    margin: auto;
  }
  